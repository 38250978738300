/**
 * Media queries
 *
 * Contains defined media queries
 *
 */

// Large (desktop)
$device-large-max: 780px;
$device-large-min: 770px;

// Medium (tablet portrait)
$device-medium-max: 769px;
$device-medium-min: 768px;

// Small (phone)
$device-small-max: 767px;
$device-small-min: 0px;
