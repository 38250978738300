/**
  * StartPage
  *
  * Styling for the StartPage.
  *
  *
**/

@import "styles/spacings";
@import "styles/typography";
@import "styles/media-queries";

.reportpage {
	@media (max-width: $device-small-max) {
		padding-top: $spacing-7x;
	}

	.react-datepicker-wrapper {
		width: 100%;
		display: block;

		.react-datepicker__input-container {
			display: block;
		}
	}

	&__calendar {
		width: 89%;
		max-width: 40rem;
	}

	&__calendarbtn {
		background-color: color(white);
		@include font-size($p-sizes);
		border: 0.1rem solid transparent;
		width: 100%;
		color: color(grey);
		border-radius: 4rem;
		margin: 0;
		padding: 1rem $spacing-3x;
		text-align: left;
		height: 4.6rem;

		&::placeholder {
			color: color(grey-75);
		}

		&:disabled {
			border-color: color(grey-10);

			&::placeholder {
				color: color(grey-75);
			}
		}

		&:focus {
			-webkit-box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
			-moz-box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
			box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
			border: 0.1rem solid color(turquoise);
			outline: 0;
		}
	}

	.react-datepicker__close-icon {
		top: 6%;
	}

	&__button {
		margin-top: $spacing-4x;
	}

	&__link {
		a {
			@extend %text-link;
			color: color(white);
			@include font-size($p-sizes);
		}
	}
}
