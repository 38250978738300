/**
  * LoginPage
  *
  * Styling for the LoginPage.
  *
  *
**/

@import "styles/spacings";
@import "styles/typography";
@import "styles/media-queries";

.loginpage {
	@media (max-width: $device-small-max) {
		margin-bottom: 7rem !important;
	}

	&__form {
		margin-top: $spacing-6x;
	}

	&__input:nth-of-type(1) {
		margin-top: $spacing-3x;

		input {
			margin-bottom: 0;
		}
	}

	&__button {
		margin-top: $spacing-3x !important;
	}

	&__error {
		margin: 0 auto;
		width: 90%;
		@include font-size($p-sizes-small);
	}
}
