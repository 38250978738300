/**
  * StartPage
  *
  * Styling for the StartPage.
  *
  *
**/

@import "styles/colors";
@import "styles/spacings";
@import "styles/typography";

.userpage {
	padding-top: $spacing-7x;
	padding-bottom: $spacing-4x;

	&__text {
		p {
			@extend %text-paragraph;
			margin-bottom: 0;
		}

		&--bold {
			font-weight: $font-weight-bold;
		}
	}
}
