/**
  * StartPage
  *
  * Styling for the StartPage.
  *
  *
**/

@import "styles/colors";
@import "styles/spacings";
@import "styles/typography";
@import "styles/media-queries";

.aboutpage {
	padding-top: $spacing-7x;
	padding-bottom: $spacing-4x;

	&__text {
		p {
			@extend %text-paragraph;

			a {
				@extend %text-link;
				color: color(yellow);
			}
		}
	}
}
