/**
  * StartPage
  *
  * Styling for the StartPage.
  *
  *
**/

@import 'styles/colors';
@import 'styles/spacings';
@import 'styles/typography';
@import 'styles/media-queries';

.historypage {
	@media (max-width: $device-small-max) {
		margin-top: $spacing-7x !important;
	}

	.table {
		background-color: white;
	}

	&__heading {
		@extend %text-h2;
		color: white;
	}

	&__section {
		padding: $spacing-4x 0;	
	}

	&__date {
		min-width: 13rem;
	}
}
