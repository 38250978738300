/**
  * StartPage
  *
  * Styling for the StartPage.
  *
  *
**/

@import "styles/colors";
@import "styles/spacings";
@import "styles/typography";
@import "styles/media-queries";

.confirmationpage {
	text-align: center;
	@include font-size($p-sizes);

	@media (max-width: $device-small-max) {
		padding-top: $spacing-7x;
	}

	&__button {
		margin-top: $spacing-5x;

		:first-child {
			margin-right: $spacing-2x;
		}
	}

	&__heading {
		@extend %text-h2;
		font-weight: $font-weight-bold;
		margin-top: $spacing-2x;
	}
}
