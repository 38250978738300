/**
  * MainMenu
  *
  * Styling for the MainMenu.
  *
  *
**/

@import "styles/colors";
@import "styles/spacings";
@import "styles/typography";
@import "styles/media-queries";

.mainmenu {
	flex-grow: 1;
	margin-bottom: 1rem;
	z-index: 9;

	&__list {
		list-style: none;
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 0;

		&__item {
			display: inline-block;
			
			a {
				text-decoration: none;
				color: color(white);
				padding-bottom: $spacing-1x;
				border-bottom: 0.3rem solid transparent;

				&:hover, &.active {
					border-bottom: 0.3rem solid color(white);	
				}

				.icon {
					display: none;
				}
			}

			&--first {
				flex-grow: 1;
			}

			&--second {
				padding-right: 5rem;
			}
		}
	}

	@media (max-width: $device-small-max) {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0;
		background-color: color(white);
		padding: $spacing-1x $spacing-3x;

		&__list {

			&__item {
				a, &.active {
					border: none !important;
					padding: 0;
				}

				a {
					display: flex;
					flex-direction: column;
					align-items: center;
					color: color(grey);
					font-size: 0.9rem;

					.icon {
						display: block;
						font-size: 2.4rem;
						margin-bottom: $spacing-05x;
					}

					&.active {
						color: color(blue);
					}
				}

				&--first {
					flex-grow: 0;
				}
	
				&--second {
					padding-right: 0;
					padding-left: $spacing-2x;
				}
			}
		}
	}
}
