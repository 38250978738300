/**
  * @stylesheet components/Dummy.scss Dummy
  * @parent styleguide
  *
  * @description
  * Styling for the Dummy.
  *
  * version
  * 3.0.0
  *
**/

@import "styles/spacings";

.loader {
	$font-size: 3rem;
	font-size: $font-size;
	line-height: $font-size;
	vertical-align: middle;
	text-align: center;
	display: block;
	animation: spin 2s infinite linear;

	&--large {
		$font-size: 6rem;
		font-size: $font-size;
		line-height: $font-size;
		margin-left: auto;
		margin-right: auto;
		padding: $spacing-2x 0;
	}

	&--small {
		$font-size: 2.5rem;
		font-size: $font-size;
		line-height: $font-size;
		margin: 0 $spacing-2x;
	}
}


@keyframes spin {
	0% {
		transform: rotate(0deg) scale(1);;
	}
	100% {
		transform: rotate(359deg) scale(1);;
	}
}
