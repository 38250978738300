/**
  * MobileHeader
  *
  * Styling for the MobileHeader.
  *
  *
**/

@import "styles/colors";
@import "styles/typography";
@import "styles/spacings";

.mobile-header {
	background-color: color(blue-dark);
	padding-top: $spacing-3x;
	padding-bottom: $spacing-1x;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9;

	&__content {
		display: flex;
		align-items: flex-start;
	}

	&__heading {
		flex-grow: 1;
		text-align: center;

		@extend %text-paragraph;
		color: color(white);
		font-weight: $font-weight-normal;
		margin: 0;
		margin-left: -$spacing-3x;
		pointer-events: none;
	}

	a {
		text-decoration: none;

		.icon {
			font-size: 2.4rem;
		}
	}
}
