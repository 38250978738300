/**
 * Colors
 *
 * Contains theme colors
 *
 */

/**
 * Table of Contents:
 *
 * 1.0 - Colors
 * - 1.1 Color usage
 * 2.0 - Functions
 * -----------------------------------------------------------------------------
 */

/**
 * 1.0 Colors
 * -----------------------------------------------------------------------------
 */

$colors: (
	black:			#000,
	black-opacity-40: rgba(0, 0, 0, 0.4),
	white:			#fff,

	blue:			#2C71C3,
	blue-light:		#7285B1,
	blue-lighter:	#E8EBF2,
	blue-lightest:	#F6F7F8,
	blue-dark:		#2A4789,
	blue-darker:	#1C3469,

	grey: 			#4D4D4D,
	grey-75:		#7A7A7A,
	grey-60:		#949494,
	grey-40:		#B8B8B8,
	grey-20:		#DBDBDB,
	grey-10:		#EDEDED,
	grey-5:			#F4F4F4,

	red: 			#B80017,
	yellow:			#F8E71C,
);

/**
 * 1.1 Color usage
 * -----------------------------------------------------------------------------
 */
$text-color:			color(grey);
$text-color-light:		color(grey-75);
$link-color:			color(white);
$error-color:			color(red);


/**
 * 2.0 - Functions
 * -----------------------------------------------------------------------------
 */
@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}
