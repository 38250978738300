/**
  * PageHeader
  *
  * Styling for the PageHeader.
  *
  *
**/

@import "styles/colors";
@import "styles/spacings";
@import "styles/media-queries";
@import "styles/typography";

.page-header {
	margin-top: $spacing-3x;

	.layout {
		overflow: visible;
	}

	&__content {
		border-bottom: 0.1rem solid color(blue-light);
		display: flex;
		align-items: flex-end;
		position: relative;
	}

	&__user {
		position: absolute;
		top: 0;
		right: 0;

		.icon {
			font-size: 1.6rem;
			margin-right: $spacing-05x;
		}
	}

	&__text {
		font-size: 1.4rem;
		margin: 0;
		text-align: right;

		&--bold {
			font-weight: $font-weight-bold;
		}
	}

	&__logo {
		margin-left: $spacing-4x;
		margin-bottom: $spacing-2x;
		margin-right: $spacing-6x;

		img {
			width: 12rem;
			height: 12rem;
			-webkit-box-shadow: 0px 0px 16px 3px rgba(0,0,0,0.28);
			-moz-box-shadow: 0px 0px 16px 3px rgba(0,0,0,0.28);
			box-shadow: 0px 0px 16px 3px rgba(0,0,0,0.28);
			border-radius: 50%;
		}
	}

	@media (max-width: $device-small-max) {
		margin-top: 0;

		&__content {
			justify-content: center;
			border: none;
		}

		&__logo {
			margin-top: $spacing-3x;
		}
	}
}
