/**
 * Global
 *
 * Contains some global styling.
 *
 */

@import "styles/typography";
@import "styles/spacings";
@import "styles/colors";
@import "styles/media-queries";

*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	font-size: $font-size-base;
	height: 100%;
}

body {
	font-family: $font-family-base;
	margin: 0;
	font-size: $font-size-standard;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&:not(.sb-show-main) {
		color: color(white);

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2c71c3+0,2a4789+100 */
		background: rgb(44,113,195); /* Old browsers */
		background: -moz-linear-gradient(-45deg, rgba(44,113,195,1) 0%, rgba(42,71,137,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(-45deg, rgba(44,113,195,1) 0%,rgba(42,71,137,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(135deg, rgba(44,113,195,1) 0%,rgba(42,71,137,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c71c3', endColorstr='#2a4789',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	}
}

#root {
	height: 100%;
	overflow: auto;
}

#main-content {
	margin: $spacing-4x 0 $spacing-5x;
}

.is-visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;

	&.focusable:active, &.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}