/**
  * StartPage
  *
  * Styling for the StartPage.
  *
  *
**/

@import "styles/colors";
@import "styles/spacings";
@import "styles/typography";
@import "styles/media-queries";

.startpage {
	&__heading {
		@extend %text-h1;
		color: color(yellow);
		text-transform: uppercase;
		max-width: 40rem;
		word-break: break-word;
		hyphens: auto;
	}

	&__subheading {
		@extend %text-h2;
	}

	&__text {
		@extend %text-paragraph;

		a {
			@extend %text-link;
			color: color(yellow);
		}

		&--toggle {
			margin-right: $spacing-2x;
		}
	}

	&__section {
		margin-top: $spacing-5x;

		&__heading {
			@extend %text-h3;
		}
	}

	&__buttons {
		margin-top: $spacing-6x;

		.button {
			margin-bottom: $spacing-1x;

			@media (max-width: $device-medium-max) {
				display: flex;
				justify-content: space-between;
				width: 100%;
				text-transform: none;
			}

			.icon {
				font-size: 2.2rem;
			}
		}
	}
}
