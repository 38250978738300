/**
  * Brain
  *
  * Styling for the Brain.
  *
  *
**/

@import "styles/media-queries";
@import "styles/spacings";

.brain {
	position: absolute;
	width: 44rem;
	height: 36rem;
	opacity: 0.15;
	top: 20rem;
	z-index: -1;
	left: 35rem;
	right: 0;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: 810px) {
		left: 20rem;
	}

	@media (max-width: $device-small-max) {
		width: 29rem;
		height: 24rem;
		left: 0;
		bottom: 10rem;
		top: auto;
	}
}
