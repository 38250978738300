/**
  * BrandHeader
  *
  * Styling for the BrandHeader.
  *
  *
**/

@import "styles/colors";
@import "styles/typography";
@import "styles/media-queries";

.brand-header {
	&__heading {
		@extend %text-h1;
		color: color(yellow);
		text-transform: uppercase;
		max-width: 40rem;
		word-break: break-word;
		hyphens: auto;
	}

	&__subheading {
		@extend %text-h2;
	}

	@media (max-width: $device-small-max) {
		&__heading, &__subheading {
			text-align: center;
		}
	}
}
