/**
 * Forms
 *
 * Styling for forms.
 *
 */

/**
 * Table of Contents:
 *
 * 1.0 - General styles to extend
 * 2.0 - Forms
 * 	- 2.1 - General form elements
 * 	- 2.2 - Form items
 *  - 2.3 - Form buttons
 * -----------------------------------------------------------------------------
 */

@import "styles/colors";
@import "styles/typography";
@import "styles/spacings";
@import "styles/media-queries";

/**
 * 1.0 General styles to extend
 * -----------------------------------------------------------------------------
 */

%field {
	@include font-size($p-sizes);
	border: 0.1rem solid transparent;
	width: 100%;
	color: color(grey);
	border-radius: 4rem;
	margin: 0;
	padding: 1rem $spacing-3x;

	&::placeholder {
		color: color(grey-75);
	}

	&:disabled {
		border-color: color(grey-10);

		&::placeholder {
			color: color(grey-75);
		}
	}

	&:focus {
		-webkit-box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
		-moz-box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
		box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
		border: 0.1rem solid color(turquoise);
		outline: 0;
	}
}

%button {
	@extend %typography-base;
	@include font-size($p-sizes);

	vertical-align: middle;
	text-align: center;
	padding: 1.5rem 3.1rem 1.2rem;
	display: inline-block;
	background-color: color(white);
	border-radius: 3rem;
	text-transform: uppercase;
	cursor: pointer;
	color: color(grey);
	text-decoration: none;
	transition: background-color 0.4s ease-out;

	&:hover {
		background-color: color(grey-5);
	}

	&:focus {
		-webkit-box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
		-moz-box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
		box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
		outline: 0;
	}
}

/**
 * 2.0 Forms
 * -----------------------------------------------------------------------------
 */

/**
 * 2.1 General form elements
 * -----------------------------------------------------------------------------
 */

.form {
	width: 75%;
	margin: 0 auto;

	@media (max-width: $device-medium-max) {
		width: 100%;
		margin: 0;
	}

	&--center {
		text-align: center;
	}

	&__headline {
		@extend %text-h2;
	}

	&__subheading {
		@include font-size($p-sizes-small);
		font-weight: $font-weight-normal;
		margin-bottom: 0;
	}

	&__fieldset {
		border: none;
		padding: 0;
		margin: 0;
	}

	&__description {
		@extend %text-paragraph;
	}
}


/**
 * 2.2 Form items
 * -----------------------------------------------------------------------------
 */

 .form {
	&__item {
		margin: $spacing-2x 0;

		&--Text, &--Textarea {
			input {
				@extend %field;
				font: $font-weight-normal 1.6rem $font-family-base !important;
				margin: 1.4rem 0;
			}
		}

		&--Textarea {
			margin-top: 0;

			textarea {
				@extend %field;
				resize: vertical;
				min-height: 7rem;
				font-family: $font-family-base;
				border-radius: 2.4rem;
			}
		}

		&--Checkbox, &--Radio {
			label {
				display: flex;
				align-items: center;
				background-color: color(white);
				margin: 0;
				@extend %field;
				padding-top: 1rem;
				padding-bottom: 1rem;

				input {
					margin-right: $spacing-1x;
				}
			}
		}

		&--Select {
			select:not([multiple]) {
				-webkit-appearance: none;
				-moz-appearance: none;
				-ms-appearance: none;
				-o-appearance: none;
					appearance: none;
				display: block;
				@extend %field;
				background-color: color(white);
				border-radius: 4rem;
				margin: 2.8rem 0 0 0;
				padding: 1rem $spacing-3x;
				font-family: $font-family-base;
			}

			.select-single {
				position: relative;
				display: block;

				.icon {
					position: absolute;
					right: 1.4rem;
					top: 1.2rem;
					font-size: 2rem;
					font-weight: $font-weight-extra-bold;
					pointer-events: none;
					color: color(grey);
				}
			}
		}
		
		&--Multiple {
			.select__control {
				padding: $spacing-05x 0 $spacing-05x 0;
				border: 0.1rem solid transparent;
				border-radius: 0;
				border-bottom: 0.1rem solid color(grey);

				&--is-focused {
					-webkit-box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
					-moz-box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
					box-shadow: 0 0 0.5rem 0.1rem rgba(74,144,226,1);
					border: 0.1rem solid color(turquoise) !important;
					outline: 0;
				}

				&:hover {
					border-color: transparent;
					border-bottom-color: color(grey);
				}
			}

			.select__value-container {
				padding: 0;
			}

			.select__multi-value {
				border-radius: 0.8rem;
				background-color: color(yellow);
				color: color(white);

				&__remove {
					border-top-right-radius: 0.8rem;
					border-bottom-right-radius: 0.8rem;
					cursor: pointer;
				}

				&__label {
					padding-right: $spacing-05x; 
					color: color(white);
				}
			}

			.select__placeholder {
				@include font-size($p-sizes);
				margin-bottom: 0;
			}

			.select__option {
				@include font-size($p-sizes);
				margin-bottom: 0;

				&--is-focused {
					background-color: lighten(color(yellow), 40%);
				}
			}
		}
	}
 }


 /**
 * 2.3 Form buttons
 * -----------------------------------------------------------------------------
 */

 .form {
	&__button {
		@extend %typography-base;
		@include font-size($p-sizes);
		font-weight: $font-weight-bold;

		&--Submit {
			@extend %button;
			border: none;
			font-weight: $font-weight-normal;
			background-color: color(blue-dark);
			color: color(white);

			&:hover {
				background-color: color(blue-dark);
			}
		}
	}
 }
