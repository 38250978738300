/**
  * Table
  *
  * Styling for the Table.
  *
  *
**/
@import "styles/typography";
@import "styles/colors";
@import "styles/spacings";


.table {
	border-spacing: 0;
	color: $text-color;
	width: 100%;

	caption {
		display: none;
	}

	&__heading {
		@include font-size($p-sizes-small);
		font-weight: $font-weight-normal;
		text-align: left;

		&--sortable {
			cursor: pointer;
		}

		.icon {
			margin-left: $spacing-05x;
		}
	}

	&__body {
		tr {
			&:nth-child(odd) td {
				background-color: color(blue-lightest);
			}

			&:last-child td {
				border-bottom: 0.1rem solid color(grey-20);
			}

			&:hover td {
				background-color: color(blue-lighter);
				cursor: pointer;
			}
		}

		td {
			@include font-size($p-sizes);
			padding: $spacing-1x $spacing-2x;  	
			border-top: 0.1rem solid color(grey-20);

			a {
				@extend %text-link;
				color: $link-color;
			}
		}
	}

	&__head {
		th {
			padding: $spacing-1x $spacing-2x;  	
		}
	}
}


@media (max-width: 1090px) {
	.table {
		margin-bottom: 0;
		width: auto;
		max-width: none;
		table-layout: fixed;

		&__wrapper {
			width: 100%;
			overflow-y: auto;
			position: relative;
		}
	}

}